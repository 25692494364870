var render, staticRenderFns
import script from "./GalleryApp.vue?vue&type=script&lang=js&"
export * from "./GalleryApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\wamp64_\\www\\4g24api\\themes\\4g24-theme\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41792134')) {
      api.createRecord('41792134', component.options)
    } else {
      api.reload('41792134', component.options)
    }
    
  }
}
component.options.__file = "resources/assets/js/components/GalleryApp.vue"
export default component.exports